<template>
  <!-- 订单详情 -->
  <div class="product">
    <img :src="option.img" alt="" />
    <div class="detail">
      <div>
        <div class="name">{{ option.name }}</div>
        <div class="num">Number：x{{ option.totalNum }}</div>
      </div>
      <span class="price">{{ buyPrice }}</span>
    </div>
  </div>
</template>
<script>
import Decimal from "decimal.js";
export default {
  props: {
    option: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    buyPrice() {
      const { price, unit, totalNum } = this.option;
      let total = new Decimal((price).replace(/[^\d.]/g, '')).mul(totalNum);
      return unit + total;
    },
  },
};
</script>
<style lang="scss" scoped>
.product {
  box-sizing: border-box;
  border-radius: 5px;
  // background-color: #d2d2d2;
  height: 96px;
  width: 100%;
  display: grid;
  padding: 8px;
  gap: 16px;
  grid-template-columns: 80px 1fr;
  img {
    border-radius: 6px;
    height: 80px;
    width: 80px;
    object-fit: cover;
  }
  .detail {
    display: flex;
    justify-content: space-between;
  }
  .name {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
  }
  .num {
    margin-top: 6px;
    color: #858585;
    font-size: 14px;
    font-weight: 400;
  }
  .price {
    line-height: 30px;
    font-size: 20px;
    font-weight: 600;
  }
}
</style>
