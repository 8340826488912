<template>
  <div class="wsp__message">
    <el-form :model="contactForm" :rules="contactRules" ref="contactForm">
      <div style="margin-bottom: 24px">Contact</div>
      <el-form-item prop="email">
        <el-input placeholder="Email" v-model="contactForm.email"></el-input>
      </el-form-item>
    </el-form>
    <div class="wsp__text">Shipping address</div>
    <CustomForm ref="shipping"></CustomForm>
    <div class="wsp__text">Billing address</div>
    <el-radio-group class="wsp__radio-group" v-model="useSameAddress">
      <el-radio class="wsp__radio" :label="true">Same as shipping address</el-radio>
      <el-radio class="wsp__radio" :label="false"
        >Use a different billing address</el-radio
      >
    </el-radio-group>
    <CustomForm
      ref="billing"
      v-show="!useSameAddress"
    ></CustomForm>

    <!-- 订单详情 -->
    <OrderDetail v-for="item in detailList" :key="item.name" :option="item"></OrderDetail>

    <!-- 总价 -->
    <div class="wsp__total">
      <span class="wsp__price" :style="`color: ${data.customize.themeColor}`">{{ buyPrice }}</span>
    </div>
    <!-- 优惠劵 -->
    <div>
      <el-input
        style="width: 160px; margin-right: 16px"
        placeholder="Discount Code"
        v-model="discountCode"
      ></el-input>
      <span
        @click="handleApply"
        style="margin-left: auto"
        :style="discountCode ? `background: ${data.customize.themeColor}` : `background: #ccc`"
        :class="discountCode ? 'wsp__apply' : 'wsp__disabled'"
      >
        <span>apply</span></span
      >
    </div>
    <!-- bottom -->
    <div class="wsp__bottom">
      <span v-if="!data.buy" @click="handleReturn" class="wsp__back" :style="`color: ${data.customize.themeColor}`">
        &lt; Return to Product List
      </span>
      <span style="margin-left: auto" @click="handlePay" class="wsp__next" :style="`background: ${data.customize.themeColor}`">
        <span>Next</span></span
      >
    </div>
    <div style="height: 60px"></div>
  </div>
</template>
<script>
import CustomForm from "./Form.vue";
import Decimal from "decimal.js";
import { validateEmail } from "./validate";
import OrderDetail from "./OrderDetail.vue";
import Vue from "vue";
import {
  InputNumber,
  Radio,
  RadioGroup,
  Form,
  FormItem,
  Message,
} from "element-ui";

Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Form);
Vue.use(FormItem);

export default {
  inject: ["data"],
  components: { CustomForm, OrderDetail },
  data() {
    return {
      contactForm: {
        email: "",
      },
      contactRules: {
        email: [{ validator: validateEmail, trigger: "blur" }],
      },
      useSameAddress: true,
      discountCode: "",
    };
  },
  computed: {
    buyPrice() {
      if (this.data.buy) {
        const { price, unit, totalNum } = this.data.buy;
        let total = new Decimal(price.replace(/[^\d\.]/g, '')).mul(totalNum);
        return unit + total;
      } else {
        const { totalNum } = this.data;
        return totalNum;
      }
    },
    detailList() {
      return this.data.buy ? [this.data.buy] : this.data.productList;
    },
  },
  methods: {
    handleReturn() {
      this.data.step = 1;
    },
    handlePay() {
      // 校验shipping和billing表单
      const shippingVaild = this.$refs.shipping.validate();
      const billingVaild = this.$refs.billing.validate();
      const vaild = this.useSameAddress
        ? shippingVaild
        : billingVaild && shippingVaild;
      // 校验contact表单
      this.$refs.contactForm.validate((value) => {
        if (value && vaild) {
          this.data.useSameAddress = this.useSameAddress;
          this.data.shippingForm = this.$refs.shipping.addressForm;
          this.data.shippingForm.email = this.contactForm.email;

          this.data.billingForm = this.$refs.billing.addressForm;
          this.data.billingForm.email = this.contactForm.email;

          this.data.step = 3;
        }
      });
    },

    handleApply() {
      if (!this.discountCode) return;
      // 校验优惠码
      if (this.discountCode !== "TD6HMI5V") {
        Message({
          message: "Discount code does not exist",
          type: "warning",
        });
        return;
      } else {
        window.open(
          "https://www.amazon.com/stores/Nuroum/page/677CD78D-C387-49F6-97B5-9EAB8114A168?ref_=ast_bln",
          "_blank"
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wsp__message {
  height: 100%;
  padding: 24px 24px;
  .wsp__text {
    margin-top: 36px;
    margin-bottom: 24px;
  }
  .wsp__radio-group {
    display: flex;
    flex-direction: column;
  }
  .wsp__radio {
    margin-bottom: 16px;
  }
  .wsp__price {
    font-size: 28px;
    line-height: 40px;
    font-weight: 800;
  }
  .wsp__total {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    justify-content: flex-start;
    .wsp__name {
      font-size: 16px;
      margin-right: 16px;
    }
    // align-items: center;
  }
  .wsp__bottom {
    box-sizing: border-box;
    height: 60px;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-right: 48px;
    padding-bottom: 8px;
    padding-top: 8px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;

    .wsp__back {
      line-height: 45px;
      text-align: center;
      cursor: pointer;
    }
  }
  .wsp__next {
    padding: 0 32px;
    line-height: 45px;
    text-align: center;
    border-radius: 11px;
    text-align: center;
    color: white;
    cursor: pointer;
  }
  .wsp__apply {
    font-size: 14px;
    padding: 9px 16px;
    line-height: 40px;
    text-align: center;
    border-radius: 10px;
    text-align: center;
    color: white;
    cursor: pointer;
  }
  .wsp__disabled {
    font-size: 14px;
    padding: 9px 16px;
    line-height: 40px;
    text-align: center;
    border-radius: 10px;
    background: #ccc;
    text-align: center;
    color: white;
  }
}
</style>
