<template>
  <div class="wsp__footer">
    <div class="wsp__subtotal">
      <span style="font-size: 20px">Subtotal</span>
      <animated-number :value="subtotal" class="wsp__price" :formatValue="formatToPrice" :duration="300" />
    </div>
    <div class="wsp__button" :style="`background-color: ${data.customize.themeColor}`" @click="handleClick">Checkout</div>
    <div class="wsp__promise">
      <div class="wsp__item">
        <img src="../../assets/promise1.png" alt="free-shipping" />
        <p>Free Shipping on All Orders</p>
      </div>
      <div class="wsp__item">
        <img src="../../assets/promise2.png" alt="free-shipping" />
        <p>Extended Warranty & Refund</p>
      </div>
      <div class="wsp__item">
        <img src="../../assets/promise3.png" alt="free-shipping" />
        <p>Exclusive Customer Service</p>
      </div>
    </div>
  </div>
</template>
<script>
import Decimal from 'decimal.js';
import AnimatedNumber from "animated-number-vue";
export default {
  components: {
    AnimatedNumber
  },
  inject: ['data'],
  data() {
    return {
      unit: '$',
    };
  },
  computed: {
    subtotal() {
      let total = new Decimal(0);
      this.data.productList.forEach((item) => {
        const str = item.price;
        const amount = new Decimal(str.replace(/[^\d.]/g, ''));
        if (!isNaN(amount)) {
          const number = new Decimal(item.totalNum)
          total = total.add(amount.mul(number))
        }
      });
      return total.toString();
    },
  },
  mounted() {
  },
  methods: {
    // 传出总价，进入下一步
    handleClick() {
      if (this.data.productList.length === 0) {
        return
      }
      this.data.totalNum = this.unit + this.subtotal
      this.data.step = 2
    },
    formatToPrice(value) {
      return `${this.unit}${value.toFixed(2)}`;
    }
  }
};
</script>
<style lang="scss" scoped>
.wsp__footer {
  border-top: 1px solid #ccc;
  padding: 36px 18%;
  display: grid;
  gap: 16px;
  grid-template-rows: 1fr 1fr 2fr;

  .wsp__subtotal {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .wsp__price {
      font-size: 32px;
      line-height: 40px;
      font-weight: 800;
    }
  }

  .wsp__button {
    border-radius: 24.5px;
    line-height: 48.75px;
    background-color: #f25929;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }

  .wsp__promise {
    display: flex;
    justify-content: space-around;

    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr !important;
    // gap: 36px;
    img {
      height: 64px;
      width: 64px;
      margin: 0 auto;
    }

    p {
      margin-bottom: 0;
      margin-top: 0;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    }

    .wsp__item {
      display: flex;
      height: 100%;
      width: 100%;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 782px) {
  .wsp__footer {
  padding: 36px 36px;
  }
}
</style>
