<template>
  <el-form :model="addressForm" :rules="rules" ref="form">
    <div class="wsp__inline">
      <el-form-item prop="firstName">
        <el-input
          placeholder="First name"
          v-model="addressForm.firstName"
        ></el-input>
      </el-form-item>
      <span></span>
      <el-form-item prop="lastName">
        <el-input
          placeholder="Last name"
          v-model="addressForm.lastName"
        ></el-input>
      </el-form-item>
    </div>
    <div class="wsp__inline">
      <el-form-item prop="country">
        <el-select
          style="width: 100%"
          v-model="addressForm.country"
          filterable
          placeholder="Country/Region"
        >
          <el-option
            v-for="item in regionOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <span></span>
      <el-form-item prop="state">
        <el-input placeholder="State" v-model="addressForm.state"></el-input>
      </el-form-item>
    </div>
    <div class="wsp__inline">
      <el-form-item prop="city">
        <el-input placeholder="City" v-model="addressForm.city"></el-input>
      </el-form-item>
      <span></span>
      <el-form-item prop="postcode">
        <el-input
          placeholder="Postcode"
          v-model="addressForm.postcode"
        ></el-input>
      </el-form-item>
    </div>
    <el-form-item prop="street">
      <el-input placeholder="Street" v-model="addressForm.street"></el-input>
    </el-form-item>
    <div class="wsp__phone">
      <el-form-item prop="telRegion">
        <el-select
          placeholder="Region"
          v-model="addressForm.telRegion"
          filterable
        >
          <el-option
            v-for="item in telOptions"
            :key="item.short"
            :label="item.tel"
            :value="item.tel"
          >
            <span style="float: left">{{ item.tel }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              item.short
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <span></span>
      <el-form-item prop="phone">
        <el-input placeholder="Phone" v-model="addressForm.phone"></el-input>
      </el-form-item>
    </div>
  </el-form>
</template>
<script>
import { telOptions } from "./country";
import { validateInput, validateRegion } from "./validate";
import Vue from "vue";
import { InputNumber, Form, Input, Select, Option } from "element-ui";

Vue.use(InputNumber);
Vue.use(Form);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
export default {
  inject: ["data"],
  data() {
    return {
      regionOptions: [],
      telOptions: [],
      addressForm: {
        country: "US",
        firstName: "",
        lastName: "",
        state: "",
        city: "",
        postcode: "",
        street: "",
        phone: "",
        telRegion: "+1",
      },
      rules: {
        country: [{ validator: validateRegion, trigger: "change" }],
        firstName: [{ validator: validateInput, trigger: "blur" }],
        lastName: [{ validator: validateInput, trigger: "blur" }],
        state: [{ validator: validateInput, trigger: "blur" }],
        city: [{ validator: validateInput, trigger: "blur" }],
        postcode: [{ validator: validateInput, trigger: "blur" }],
        street: [{ validator: validateInput, trigger: "blur" }],
        phone: [{ validator: validateInput, trigger: "blur" }],
      },
    };
  },
  created() {
    this.regionOptions = [
      {
        label: "United States of America",
        value: "US",
      },
    ];
    this.telOptions = telOptions;
  },
  methods: {
    validate(cb) {
      if (cb) {
        this.$refs.form.validate(cb);
      } else {
        let value;
        this.$refs.form.validate((vaild) => {
          value = vaild;
        });
        return value;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wsp__inline {
  display: grid;
  grid-template-columns: 11fr 1fr 11fr;
}
.wsp__phone {
  display: grid;
  grid-template-columns: 5fr 1fr 17fr;
}
</style>
