import Vue from "vue";
import payment from "./index.vue";

function appendResource(url) {
  return new Promise((resolve) => {
    const head = document.head || document.getElementsByTagName("head")[0];
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = url;
    head.appendChild(link);
    link.onload = () => {
      resolve();
    };
  });
}

class Payment {
  currentDrawer = null;
  readyed = false;
  // opts= null

  constructor(opts) {
    this.opts = opts || {
      themeColor: '#f25929',
      brand: 'nuroum' // nuroum | nearhub | nearstream
    }
  }

  async ready(callback) {
    if (this.readyed) {
      callback && callback.call(null);
      return;
    }
    await appendResource(
      "https://unpkg.com/element-ui@2.15.6/lib/theme-chalk/index.css"
    );
    this.init();
    this.readyed = true;
    callback && callback.call(null);
  }

  async init() {
    const paymentInstance = Vue.extend(payment);
    this.currentDrawer = new paymentInstance();
    let newPayment = this.currentDrawer.$mount().$el;
    document.body.appendChild(newPayment);
    this.currentDrawer.app.customize = this.opts
  }

  // 打开购物车,展示商品列表页
  open() {
    this.currentDrawer.handleOpen();
  }

  // 关闭购物车
  close() {
    this.currentDrawer.handleClose();
  }

  // 新增一种商品
  add(option) {
    this.currentDrawer.handleAdd(option);
  }

  // 购买商品
  buy(option) {
    this.currentDrawer.handleBuy(option);
  }

  // 删除一种商品
  delete(option) {
    this.currentDrawer.handleDeleteProduct(option);
  }

  // 返回商品列表
  list() {
    return this.currentDrawer.app.productList;
  }
}

// window.wsPayment = () => {
//   const wsPayment = new Payment();
//   return wsPayment;
// };

//   window.WsPayment = new Payment({
//   themeColor: '#f25929',
//   brand: 'nearstream' // nuroum | nearhub | nearstream
// });
// window.WsPayment.init()

window.WsPayment = Payment
export default Payment;
