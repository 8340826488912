<template>
  <el-drawer
    ref="payment"
    :show-close="false"
    :size="data.isMobile ? '100%' : '540px'"
    :visible.sync="data.value"
    :before-close="handleClose"
    direction="rtl"
  >
    <!-- header -->
    <template slot="title">
      <div style="display: flex; align-items: center">
        <img @click="handleClose" src="./assets/back.png" alt="close" />
      </div>
      <h3 class="wsp__header_content">{{ headerText }}</h3>
    </template>
    <!---->
    <Transition name="fade">
      <KeepAlive exclude="Cashier">
        <component
          class="wsp__content"
          :productList="data.productList"
          :is="data.componentMap[data.step]"
        ></component>
      </KeepAlive>
    </Transition>
  </el-drawer>
</template>
<script>
import Vue from "vue";
import ProductList from "./component/ProductList/index.vue";
import Message from "./component/Message/index.vue";
import Cashier from "./component/Cashier/index.vue";
import { Drawer } from "element-ui";
Vue.use(Drawer);
export default {
  inject: ["data"],
  components: { ProductList, Message, Cashier },
  computed: {
    headerText() {
      const map = {
        1: `SHOPPING CART(${this.data.productList.length})`,
        2: "Information",
        3: "Payment",
      };
      return map[this.data.step];
    },
  },
  methods: {
    
    handleClose() {
      this.data.buy = null;
      this.data.value = false;
    },
  },
};
</script>
<style lang="scss" scoped>
// 头部样式
::v-deep .el-drawer__header {
  padding-top: 0;
  height: 80px;
  display: grid;
  grid-template-columns: 64px auto;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0px;
  img {
    height: 24px;
    width: 24px;
  }
  img:hover {
    cursor: pointer;
  }
  .wsp__header_content {
    margin: 0;
    text-align: center;
    font-weight: 500;
    color: #141223;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
// 内容样式
.wsp__content {
  height: 100%;
}

/* fade-slide */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media screen and (max-width: 782px) {
  ::v-deep .el-drawer__header {
    .wsp__header_content {
      text-align: center;
      font-weight: 500;
      color: #141223;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
