<template>
    <Payment></Payment>
</template>
<script>
import Payment from './Payment.vue';
export default {
  components: { Payment },
  data() {
    return {
      app: {
        // customize: {
        //   themeColor: '#f25929'
        // },
        componentMap: {
          // 组件地图
          1: 'ProductList',
          2: 'Message',
          3: 'Cashier',
        },
        step: 1, // 当前步骤
        isMobile: false, // 是否是移动端
        productList: [], // 购物车商品列表
        buy: null, // 直接购买的商品详情
        value: false, // drawer开关
        productNum: 0,
        totalNum: 0, // 总价
        shippingForm: {}, // 收货地址
        billingForm: {}, //账单地址
        useSameAddress: true, // 用同一个地址
      },
    };
  },
  provide() {
    return {
      data: this.app,
      delete: this.handleDeleteProduct,
    };
  },
  mounted() {
    const list = window.localStorage.getItem('cart_list');
    if (list) {
      this.app.productList = JSON.parse(list);
    }
    this.checkScreenSize(); // 在组件挂载时检查屏幕大小
    window.addEventListener('resize', this.checkScreenSize); // 监听窗口大小变化
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize); // 组件销毁时移除窗口大小监听
  },
  watch: {
    'app.productList': {
      handler(val) {
        window.localStorage.setItem('cart_list', JSON.stringify(val));
      },
      deep: true,
    },
  },
  methods: {
    checkScreenSize() {
      this.app.isMobile = window.innerWidth < 1000; // 在这里设置媒体查询条件
    },

    // 购物车新增商品
    handleAdd(product) {
      const existingProduct = this.app.productList.findIndex((item) => item.spu === product.spu);
      if (existingProduct !== -1) {
        this.app.productList[existingProduct].totalNum += product.totalNum;
      } else {
        this.app.productList.push(product);
      }
    },

    // 直接购买商品
    handleBuy(product) {
      this.app.buy = product
      this.app.step = 2
      this.app.value = true
    },

    //删除商品
    handleDeleteProduct(option) {
      this.app.productList = this.app.productList.filter((item) => item.name !== option.name);
    },
    // 打开购物车，展示商品列表页
    handleOpen() {
      this.app.step = 1;
      this.app.value = true;
    },
    // 关闭购物车
    handleClose() {
      this.app.value = false;
    },
  },
  render() {
    return this.$slots();
  },
};
</script>
