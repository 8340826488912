<template>
  <div id="payment-wrap" class="wsp__cashier"></div>
</template>
<script>
import WashengPingPongPay from 'washeng-pppay';
const washengppy = WashengPingPongPay.getInstance();
export default {
  inject: ['data'],
  data() {
    return {};
  },
  async created() {
    this.init();
  },
  methods: {
    async init() {
      await washengppy.init({
        /**语言，不传默认英语 - de德语 en英语 es西班牙语 fr法语 it意大利语 ru俄语 zh中文 jp日语 */
        lang: 'en',

        // 沙箱: sandbox 生产：production
        mode: 'production',
        
        // PingPong收银台在哪个元素上渲染，需创建一个对应的div用于挂载
        root: '#payment-wrap',
      });

      // 处理地址信息
      const shipping = JSON.parse(JSON.stringify(this.data.shippingForm));
      const billing = this.data.useSameAddress ? shipping : JSON.parse(JSON.stringify(this.data.billingForm));

      // 处理商品信息
      let commodityList;
      if (this.data.buy) {
        commodityList = [this.data.buy];
      } else {
        commodityList = this.data.productList;
      }

      const params = {
        billing,
        shipping,
        commodityList,
        resultUrl: "/payment-result", // 支付完毕跳转的页面
        brand: this.data.customize.brand
      };

      window.sessionStorage.setItem('latest_order', JSON.stringify(params));

      washengppy.createPayment({
        /**
         * api: "/us/api/team/getOrder"通过这种形式传入，相当于是在本仓库中处理了api的代理，处理了跨域问题。
         *
         * 由于跨域的问题，因此不建议ap直接传https:xxx.com/api/getxx的形式
         */
        api: 'https://api.nuroum.com/nuroumContact/payment/getOrder',

        requestHeaders: {
          // 'x-authorization': '',
          'Content-Type': 'application/json',
        },

        /**
         * 订单信息，及对应的订单id或该订单的其他唯一标识字段
         *
         * 失败、正在交易中 需重新唤起收银台：只需传merchantTransactionId
         */
        requestParams: JSON.stringify(params),
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.wsp__cashier {
  height: 100%;
  width: 100%;
}
</style>
