<template>
  <div class="wsp__card">
    <span class="wsp__image">
      <img @click="handleDeleteProduct" src="../../assets/delete.png" alt="" />
    </span>
    <div
      :style="`background-image: url(${option.img}); background-size: cover; background-position: center;`"
    ></div>
    <div class="wsp__message">
      <div class="wsp__name">{{ option.name }}</div>
      <div class="wsp__price">{{ option.price }}</div>
      <el-input-number
        @change="handleChangeNum"
        class="wsp__input-number"
        size="small"
        :min="0"
        :max="100"
        step-strictly
        v-model="option.totalNum"
      ></el-input-number>
    </div>
    <!-- <img src="../../assets/delete.png" alt=""> -->
  </div>
</template>
<script>
import Vue from "vue";
import { InputNumber } from "element-ui";

Vue.use(InputNumber);

export default {
  name: "ProductCard",
  props: {
    option: {
      type: Object,
      default: () => {},
    },
  },
  inject: ["delete", "data"],
  data() {
    return {};
  },
  methods: {
    handleChangeNum(currentValue) {
      if (currentValue === 0) {
      this.delete(this.option);
      }
      if (currentValue > this.maxNum) {
        this.checkProductNum(this.maxNum)
      }
    },
    handleDeleteProduct() {
      this.delete(this.option);
    },
    checkProductNum (val) {
      console.log('changeNum',val);
      const maxNum = val || 0
      if (this.option.totalNum > maxNum) {
        const index = this.data.productList.indexOf(this.option)
        this.$nextTick(() => {
        this.data.productList[index].totalNum = maxNum
        })
      }
    }
  },
  watch: {
    'data.productList': {
      handler() {
        console.log(9999999)
        if (this.option.bindingSpu) {
          this.checkProductNum()
        }
      },
      deep:true
    }
  },
  computed: {
    maxNum () {
      if (this.option.bindingSpu) {
        const currentBindingProduct = this.data.productList.find((product) => product.spu === this.option.bindingSpu)
        if (!currentBindingProduct) return 0;
        console.log(currentBindingProduct, 1);
        // this.handleDeleteProduct(maxNum.totalNum)
        // 返回绑定商品的最大数量
        return currentBindingProduct.totalNum

      }
      return 100
    }
  }
};
</script>
<style lang="scss" scoped>
.wsp__card {
  box-sizing:border-box;
  width: 100%;
  height: 180px;
  display: grid;
  gap: 24px;
  grid-template-columns: 30px 2fr 3fr;
  border-bottom: 1px solid #ccc;
  padding-bottom: 16px;
  padding-top: 16px;
  padding-left: 32px;
  .wsp__image {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .wsp__message {
    padding: 16px 16px 0 0;
    display: flex;
    flex-direction: column;
    .wsp__name {
      font-size: 20px;
      font-weight: 400;
      line-height: 23px;
    }
    .wsp__price {
      margin-top: 24px;
      line-height: 30px;
      font-size: 26px;
      font-weight: 800;
    }
    .wsp__input-number {
      margin-top: auto;
      width: 100%;
    }
  }
}
</style>
