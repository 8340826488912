<template>
  <div class="wsp__products">
    <div class="wsp__list">
        <ProductCard v-for="(item, index) in productList" :key="item.spu" :option="item"></ProductCard>
    </div>
    <Subtotal/>
  </div>
</template>
<script>
import ProductCard from './ProductCard.vue';
import Subtotal from './Subtotal.vue';
export default {
  name: 'ProductList',
  components: {ProductCard, Subtotal},
  props: {
    productList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.wsp__products {
  display: grid;
  grid-template-rows: 1fr 300px;
  .wsp__list {
    overflow: auto;
    background-color: #fff;
  }
}
</style>
