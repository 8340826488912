// 非空校验
export const validateInput = (rule, value, callback) => {
  if (value.trim() === '') {
    callback(new Error('Field cannot be empty'));
  } else {
    callback();
  }
};

// 邮箱校验
export const validateEmail = (rule, value, callback) => {
  if (value.trim() === '') {
    callback(new Error('Field cannot be empty'));
  }
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRegex.test(value.trim())) {
    callback();
  } else {
    callback(new Error('Please enter a valid email'));
  }
};

// 地区校验
export const validateRegion = (rule, value, callback) => {
  if (value !== 'US') {
    callback(new Error('Shipping unavailable in this country'));
  } else callback();
};
